/* You can add global styles to this file, and also import other style files */
body {
    font-family: 'Yusei Magic', sans-serif !important;
    font-size: 1em;
    background-color: #fbfbfb !important;
    color: #505050 !important;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Shadows Into Light Two', cursive !important;

}

.section_title {
    padding-top: 20px;
    color: #ff6f69;
}

.background {
    background-color: #fbfbfb;
    border: 5px solid #7fc9dc;
    border-radius: 15px;
}

img {
    border-radius: 15px;
}

hr {
    width: 70px;
    height: 3px !important;
    background: #505050;
    text-align: center;
}

.section {
    margin-bottom: 30px;
    padding: 20px;
    box-shadow: 0 7px 30px -10px rgba(150,170,180,0.5) !important;
}

a {
    color: #247a89 !important;
}

a:hover {
    color: #2d5c64 !important;
    text-decoration: none !important;
}

.icon {
    font-size:36px;
    color: #ff6f69 !important;
}

.icon:hover {
    color: #f3514b !important;
}

.tag {
    background-color: #4ea17e;
    color: #fff;
    padding: 5px;
    border-radius: 5px;
    margin-right: 5px;
    margin-top: 10px;
}

.monitor  {
    width:50%;
}
.laptop  {
    width:50%;
}

.tablet {
    width:30%;
}

.mobile {
    width:20%;
}
